const getSchemaScript = (JsonLD) => {
  const { jsonName, jsonDescription, jsonImage, jsonBrand } = JsonLD;

  const schemaContent = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: jsonName,
    description: jsonDescription,
    image: jsonImage?.fallbackUrl,
    brand: {
      '@type': 'Thing',
      name: jsonBrand,
    },
  };

  const schemaScript = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(schemaContent),
    },
  ];

  return schemaScript;
};

export default getSchemaScript;
