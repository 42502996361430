import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import GatsbyImage from 'components/common/GatsbyImage';
import { RetailersLinkProps } from './model';

import './RetailersLink.scss';

const RetailersLink: FC<RetailersLinkProps> = ({ retailersLink, retailersLinkItem }) => (
  <Link to={retailersLink[0].url} className="retailers-link">
    {retailersLinkItem.map(({ properties: { title, iconAlt, image } }) => (
      <div className="retailers-link__item" key={title}>
        <GatsbyImage className="retailers-link__image" fluid={image} alt={iconAlt} />
        <span className="retailers-link__title">{title}</span>
      </div>
    ))}
  </Link>
);

export const query = graphql`
  fragment FragmentRetailersLink on TCommonSettingsBlockProps {
    retailersLink {
      url
      name
      target
    }
    retailersLinkItem {
      properties {
        title
        iconAlt
        image {
          ...FragmentGatsbyImage
        }
      }
    }
  }
`;

export default RetailersLink;
