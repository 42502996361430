import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';

import { InfoBannerProps } from './model';
import './InfoBanner.scss';
import './InfoBannerOverride.scss';

const InfoBanner: FC<InfoBannerProps> = ({ description, image, imageAlt }) => (
  <div className="gs-info-banner" data-testid="info-banner">
    <div className="gs-info-banner__text">
      <Container fluid>
        <DangerouslySetInnerHtml className="gs-info-banner__description" html={description} />
      </Container>
    </div>

    <div className="gs-info-banner__image">
      {image?.fallbackUrl ? (
        <GatsbyImage
          useFocalPoint
          className="gs-info-banner__image-img"
          fluid={image}
          alt={imageAlt}
        />
      ) : null}
    </div>
  </div>
);

export default InfoBanner;
