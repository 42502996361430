import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

import './ProductInfo.scss';
import './ProductInfoOverride.scss';

const ProductInfo: FC<ProductPageTypes.IInfoSection> = ({
  blocks,
  instructions,
  ingredients,
  sectionBackground,
}) => {
  const cx = classnames({
    'product-info-section': true,
    [`product-info-section--background-${sectionBackground?.label}`]: sectionBackground?.label,
  });

  return (
    <div className={cx} data-testid="product-info">
      <Container fluid>
        <div className="product-info-section__grid">
          {blocks.map(
            ({
              properties: {
                title,
                type: [typeBlock],
                description,
                links,
              },
            }) => {
              const productInfoBlocks = {
                Instructions: instructions,
                Ingredients: ingredients,
                Other: description,
              };

              return (
                <div key={title} className="product-info-section__block">
                  <h2 className="product-info-section__title">{title}</h2>
                  <DangerouslySetInnerHtml
                    className="product-info-section__description"
                    html={productInfoBlocks[typeBlock]}
                  />
                  {links?.length
                    ? links.map((link) => (
                        <div key={link.properties.title}>
                          <Button
                            target="_blank"
                            ariaLabel={link.properties.ariaLabel}
                            variant="link"
                            to={link.properties.link.url}
                          >
                            {link.properties.title}
                            <IconCustom icon="download" />
                          </Button>
                        </div>
                      ))
                    : null}
                </div>
              );
            }
          )}
        </div>
      </Container>
    </div>
  );
};

export default ProductInfo;
