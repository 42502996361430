import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import Faq from 'components/Faq/Faq';

import './FaqBlockComponent.scss';
import './FaqBlockComponentOverride.scss';

const FaqBlockComponent: FC<ProductPageTypes.IFAQblockComponentProps> = ({
  title,
  items,
  color,
  selectedIndex,
  isExpanded,
  disableSeoSchema,
}) => {
  const bgColor = color?.length ? color[0] : null;
  const cx = classnames({
    'faq-section': true,
    [`faq-section--${bgColor}`]: bgColor,
  });

  return (
    <div className={cx} data-testid="faq-section">
      <Container fluid>
        <div className="faq-section__wrap">
          <Faq {...{ title, items, selectedIndex, isExpanded, disableSeoSchema }} />
        </div>
      </Container>
    </div>
  );
};

export default FaqBlockComponent;
