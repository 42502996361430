import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ExternalLink from 'components/common/ExternalLink';
import IconCustom from 'components/common/IconCustom';

import { IShopMessageProps } from './model';
import './ShopMessage.scss';

const ShopMessage: FC<IShopMessageProps> = ({ blocks, title, linkText, linkUrl, target }) => (
  <div className="shop-message" data-testid="shop-message">
    <Container fluid>
      {title && <h2 className="shop-message__title">{title}</h2>}
      <Row>
        {blocks?.length
          ? blocks.map(({ properties: { icon, label } }, index) => (
              <Col lg={4} md={index === blocks.length - 1 ? 12 : 6} key={label}>
                <div className="shop-message__block">
                  <IconCustom icon={icon[0]} />
                  <span className="text">{label}</span>
                </div>
              </Col>
            ))
          : null}
      </Row>
      {linkText && linkUrl && (
        <div className="shop-message__link-wrap">
          <ExternalLink
            aria-label={linkText}
            to={linkUrl}
            target={target}
            className="shop-message__link"
          >
            {linkText}
          </ExternalLink>
        </div>
      )}
    </Container>
  </div>
);
export default React.memo(ShopMessage);
