import React, { FC, useMemo } from 'react';
import { graphql, Link } from 'gatsby';

import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { sortRelatedProducts } from 'utils/helpers';

import { IConditionProductsListProps } from './model';

import './ConditionProductsList.scss';

const ConditionProductsList: FC<IConditionProductsListProps> = ({
  title,
  cardTitleTag,
  conditionProducts,
  list,
}) => {
  const itemsToRender = useMemo(() => {
    const productLinks = list?.map((item) => item?.properties?.link?.[0].url);

    return conditionProducts?.nodes?.length
      ? sortRelatedProducts(productLinks, conditionProducts?.nodes)
      : [];
  }, [list, conditionProducts]);

  return itemsToRender.length ? (
    <div className="condition-products-list">
      <DangerouslySetInnerHtml className="condition-products-list__title" html={title} />
      <div className="condition-products-list__items">
        {itemsToRender.map((product) => {
          return (
            <Link
              key={product?.cardTitle}
              className="condition-product-card__link"
              to={product?.link}
            >
              {product?.productImage?.fallbackUrl ? (
                <div className="condition-product-card__image">
                  <GatsbyImage
                    style={{ overflow: 'initial' }}
                    objectFit="contain"
                    objectPosition="50% 100%"
                    className="condition-product-card__image-img"
                    fluid={product.productImage}
                    alt={product?.productImageAlt || product?.cardTitle}
                  />
                </div>
              ) : null}
              <div className="condition-product-card__text">
                <div className="condition-product-card__title-placeholder">
                  <DangerouslySetInnerHtml
                    element={cardTitleTag?.[0] || 'div'}
                    className="condition-product-card__title"
                    html={product?.cardTitle}
                  />
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentConditionProductCard on Product {
    link
    productImage {
      ...FragmentGatsbyProps
      gatsbyImage {
        ...FragmentSmallProductImage
      }
      mobileImage {
        childImageSharp {
          fluid(maxHeight: $productImageMobileSize, quality: $productImageMobileQuality) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productImageAlt
    cardTitle
  }

  fragment FragmentConditionProductsList on TConditionProductsListStructure {
    properties {
      title
      cardTitleTag
      list {
        properties {
          link {
            url
          }
        }
      }
    }
  }
`;

export default ConditionProductsList;
