import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';

import './ProductVariats.scss';
import './ProductVariatsOverride.scss';

const ProductVariats: FC<ProductPageTypes.IProductVariants> = ({ variants, title }) => {
  return (
  <div className="product-variants" data-testid="product-variants">
    <strong className="product-variants__title">{title}</strong>
    <ul className="product-variants__list">
      {variants.map(({ properties: { icon: [name], label, image } }) => (
        <li key={label} className="product-variants__list-item">
          {image ? (
            <div className="product-variants__list-item-image">
              <GatsbyImage objectFit="contain" fluid={image}  alt={ image.altText ? image.altText: label} />
            </div>
          ) : (
            <IconCustom icon={name} />
          )}

          <DangerouslySetInnerHtml className="product-variants__list-label" html={label} />
        </li>
      ))}
    </ul>
  </div>
)};

export default ProductVariats;
